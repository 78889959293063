<template>
  <div class="user-page container-fluid">

    <form @submit.prevent="doUserEdit">

      <div class="buttons">
        <div class="float-end">
          <button class="button-confirm" type="submit">
            <span class="fa fa-check"></span>
          </button>
        </div>
        <router-link :to="{path: '/place/map'}" class="button-back">
          <span class="fa fa-arrow-left"></span>
        </router-link>
      </div>

      <div class="col-12 col-lg-4 offset-lg-4 form-group">
        <label for="name" class="form-label">Name</label>
        <input
            v-model="name"
            :class="{'is-invalid': errors?.name}"
            type="text"
            class="form-control"
            id="name" />
        <div class="invalid-feedback">{{ errors?.name }}</div>
      </div>

      <div class="col-12 col-lg-4 offset-lg-4 form-group">
        <label for="gender" class="form-label">Gender</label>
        <select
            v-model="gender"
            :class="{'is-invalid': errors?.gender}"
            class="form-control"
            id="gender">
          <option value="">...</option>
          <option v-for="(gender, key) in userGenderEnum.getLabels()" :key="key" :value="key">
            {{ gender }}
          </option>
        </select>
        <div class="invalid-feedback">{{ errors?.gender }}</div>
      </div>

      <div class="col-12 col-lg-4 offset-lg-4 form-group">
        <label for="email" class="form-label">Email<span class="f-required">*</span></label>
        <input
            v-model="email"
            :class="{'is-invalid': errors?.email}"
            type="text"
            class="form-control"
            autocomplete="false"
            id="email" />
        <div class="invalid-feedback">{{ errors?.email }}</div>
      </div>

      <div class="col-12 col-lg-4 offset-lg-4 form-group">
        <label for="phone" class="form-label">Phone</label>
        <input
            v-model="phone"
            :class="{'is-invalid': errors?.phone}"
            type="text"
            class="form-control"
            id="phone" />
        <div class="invalid-feedback">{{ errors?.phone }}</div>
      </div>

      <div class="col-12 col-lg-4 offset-lg-4 form-group">
        <label for="passport" class="form-label">i.d.<span class="f-required">*</span></label>
        <input
            v-model="passport"
            :class="{'is-invalid': errors?.passport}"
            type="text"
            class="form-control"
            id="passport" />
        <div class="invalid-feedback">{{ errors?.passport }}</div>
      </div>

    </form>

    <div class="pay-block">

      <div class="col-12 col-lg-4 offset-lg-4">

        <div class="pay-card-add-block" v-if="!this.cardData">
          <a href="#" @click.prevent="doPayCardAdd()">+ Add New Card</a>
        </div>
        <h4>Payment</h4>
        <div class="pay-card-block">
          <div class="pay-card" v-if="this.cardData">
            <div class="logo">
              {{ this.getPayCardLogo(this.cardData.extNumber) }}
            </div>
            <a href="#" @click.prevent="doPayCardDelete()" class="button">
              <span class="fa fa-times"></span>
              Delete
            </a>
            <div class="number">
              {{ this.getPayCardExtNumber(this.cardData.extNumber) }}
            </div>
            <div class="owner" v-if="this.cardData.extDateDue">
              <div class="date">
                <div class="title">Expires</div>
                {{ this.getPayCardDueDate(this.cardData.extDateDue) }}
              </div>
              <div class="name">
                <div class="title">Card Holder</div>
                <span v-if="this.cardData.extName">{{ this.getPayCardExtName(this.cardData.extName) }}</span>
                <span v-else>* * * * * *</span>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

    <div class="links">
      <router-link to="/user/password/edit" class="btn btn-secondary mx-2">Edit password</router-link>
      <router-link to="/user/logout" class="btn btn-outline-secondary">Logout</router-link>
    </div>

    <div class="col-12 col-lg-4 offset-lg-4">
      <div class="list">
      </div>
    </div>

    <div class="row">
      <div class="contacts">
        <div class="col-12 col-lg-4 offset-lg-4">
          <div class="social">
            <a :href="this.getInstagramUrl()" class="link" target="_blank">
              <span class="fa fa-instagram"></span>
            </a>
            <a :href="this.getWatsappUrl()" class="link" target="_blank">
              <span class="fa fa-whatsapp"></span>
            </a>
            <a :href="this.getFacebookUrl()" class="link" target="_blank">
              <span class="icon-round">
                <span class="fa fa-facebook"></span>
              </span>
            </a>
          </div>
          <div class="info">
            <span class="fa fa-envelope-o"></span> <a href="mailto:support@boogy.fit" target="_blank">support@boogy.fit</a> <br />
            <span class="fa fa-file-o"></span> <router-link :to="{path: '/page/agreement', query: {back: 'user'}}">User agreement</router-link><br />
            <span class="fa fa-file-o"></span> <router-link :to="{path: '/page/rules', query: {back: 'user'}}">Visit rules</router-link><br />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import PayMixin from '@/modules/pay/mixins/PayMixin';
import {UserGenderEnum} from "@/modules/user/enums/UserEnum";

export default {

  mixins: [PayMixin],

  data: () => ({
    name: null,
    passport: null,
    email: null,
    phone: null,
    gender: null,
    userGenderEnum: UserGenderEnum,

    cardData: null
  }),

  async mounted() {

    if (!await this.checkUser())
      return;

    await this.doUserEditInit();
  },

  methods: {

    async doUserEditInit() {

      let apiHolder = await this.sendApiGet('api/user/edit/init');

      if (!apiHolder.isSuccess())
        return;

      if (apiHolder.data?.userData) {

        this.email = apiHolder.data.userData?.email;
        this.phone = apiHolder.data.userData?.phone;
        this.name = apiHolder.data.userData?.name;
        this.gender = apiHolder.data.userData?.gender;
        this.passport = apiHolder.data.userData?.passport;
      }

      if (apiHolder.data?.cardData)
        this.cardData = apiHolder.data.cardData;
    },

    async doPayCardAdd() {

      let apiHolder = await this.sendApiPost('api/pay/card/add');

      if (!apiHolder.isSuccess())
        return;

      if (apiHolder.data?.processUrl)
        window.location.href = apiHolder.data.processUrl;
    },

    async doPayCardDelete() {

      let apiHolder = await this.sendApiDelete('api/pay/card/delete', {isModalMessage: true});

      if (!apiHolder.isSuccess())
        return;

      this.cardData = null;
    },

    async doUserEdit() {

      let apiHolder = await this.sendApiPost('api/user/edit', {
        name: this.name,
        passport: this.passport,
        gender: this.gender,
        email: this.email,
        phone: this.phone
      });

      if (!apiHolder.isSuccess())
        return;

      this.clearErrors();

      await this.redirect('/user/edit', 'Profile has been successfully updated', 'success');
    }
  },

  beforeUnmount() {

    this.setFlashMessage();
  }

}
</script>

<style scoped>

.buttons {
  padding-top: 15px;
  padding-bottom: 20px;
}

.links {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  font-size: 16px;
  text-decoration: none !important;
}

.list {
  padding-bottom: 30px;
}

.list .link {
  display: block;
  text-decoration: none !important;
  padding-bottom: 20px;
  color: #424347 !important;
}

.list .link span {
  display: inline-block;
  width: 48px;
  height: 48px;
  background: #C4C4C4;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 10px;
}

.contacts {
  background: #2B2B2B;
  color: #fff;
  padding: 15px 15px 15px 15px;
  border-radius: 15px 15px 0 0;
  font-size: 16px;
}

.contacts .info {
  line-height: 30px;
  padding-bottom: 10px;
}

.contacts .info a {
  color: #FFF !important;
  text-decoration: none !important;
}

.contacts .social {
  float: right;
}

.contacts .social .fa {
  width: 20px;
  color: #FFF;
  font-size: 28px;
  line-height: 20px;
  width: 25px;
  height: 25px;
  display: inline-block;
  margin-right: 15px;
}

.contacts .social .fa-whatsapp {
  color: #00D916;
}

.contacts .social .fa-instagram {
  color: #f65db1;
}

.contacts .social .icon-round {
  border-radius: 50%;
  background: #039BE5;
  display: inline-block;
  width: 25px;
  height: 25px;
  font-size: 20px;
  text-align: center;
}

.contacts .social .fa-facebook {
  color: #FFF;
  font-size: 24px;
}

.contacts .info .fa {
  width: 20px;
  color: #2CDAE5;
}

.pay-block {
  padding-top: 30px;
}

.pay-card-block {
  text-align: center;
}

.pay-card-add-block {
  float: right;
}

.pay-card-add-block a {
  color: #000 !important;
  text-decoration: none !important;
}

.pay-card-block .pay-card {
  display: inline-block;
  width: 290px;
  height: 179px;
  border-radius: 12px;
  background: #2B2B2B;
  color: #FFF;
  position: relative;
}

.pay-card-block .pay-card .number {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  font-size: 26px;
  text-align: center;
  color: #b9b9b9;
}

.pay-card-block .pay-card .owner {
  position: absolute;
  bottom: 25px;
  left: 0;
  padding: 0 35px;
  width: 100%;
  font-size: 13px;
}

.pay-card-block .pay-card .owner > div {
  text-align: left;
}

.pay-card-block .pay-card .owner .title {
  color: #A1A4B2;
}

.pay-card-block .pay-card .owner .name {
  float: left;
}

.pay-card-block .pay-card .owner .date {
  float: right;
}

.pay-card-block .pay-card .logo {
  text-align: left;
  font-style: italic;
  font-weight: bold;
  font-size: 18px;
  position: absolute;
  top: 15px;
  left: 30px;
}

.pay-card-block .pay-card .button {
  position: absolute;
  top: 10px;
  right: 15px;
  display: block;
  color: #BD3636 !important;
  font-size: 13px;
  text-decoration: none !important;
}

</style>