
export default {

	methods: {

		getPayCardExtNumber(extNumber) {

			if (!extNumber)
				return "";

			return extNumber.slice(0, 4) + " **** **** " + extNumber.slice(-4);
		},

		getPayCardExtName(extName) {

			if (!extName)
				return "";

			return extName.substr(0, 25).toUpperCase();
		},

		getPayCardDueDate(dateDue) {

			if (!dateDue)
				return null;

			let n = dateDue.toString();
			return n.replace(/(\d{2}(?=(?:\d\d)+(?!\d)))/g, "$1" + '/');
		},

		getPayCardLogo(extNumber)
		{
			if (extNumber.startsWith("2"))
				return "МИР";

			if (extNumber.startsWith("3"))
				return "American Express";

			if (extNumber.startsWith("4"))
				return "VISA";

			if (extNumber.startsWith("7"))
				return "УЭК";

			let str = extNumber.slice(0, 2);

			if (str == 50 || str == 56 || str == 57 || str == 58 || str == 63 || str == 67)
				return "Maestro";

			if (str == 51 || str == 52 || str == 53 || str == 54 || str == 55)
				return "MasterCard";

			if (str == 60)
				return "Discover";

			return "CARD";
		}

	}
}